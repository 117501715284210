@use '@angular/material' as mat;

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Open+Sans:ital,wght@0,400;0,500;0,600;1,700&display=swap');

$my-custom-typography-config: mat.define-typography-config(
  $headline-1: mat.define-typography-level(64px, 80px, 400, 'DM Sans', 0em),
  $headline-2: mat.define-typography-level(54px, 62px, 400, 'DM Sans', 0em),
  $headline-3: mat.define-typography-level(48px, 56px, 400, 'DM Sans', 0em),
  $headline-4: mat.define-typography-level(40px, 50px, 500, 'DM Sans', 0em),
  $headline-5: mat.define-typography-level(24px, 30px, 500, 'DM Sans', 0em),
  $headline-6: mat.define-typography-level(20px, 25px, 500, 'DM Sans', 0em),
  $subtitle-1: mat.define-typography-level(16px, 20px, 500, 'DM Sans', 0.0156em),
  $subtitle-2: mat.define-typography-level(14px, 17px, 700, 'DM Sans', 0.0107em),
  $body-1: mat.define-typography-level(16px, 20px, 400, 'DM Sans', 0.0156em),
  $body-2: mat.define-typography-level(14px, 18px, 400, 'DM Sans', 0.0179em),
  $button: mat.define-typography-level(14px, 14px, 500, 'DM Sans', 0.0893em),
  $caption: mat.define-typography-level(12px, 16px, 400, 'DM Sans', 0.0333em),
);

.headline-1 {
  @include mat.typography-level($my-custom-typography-config, 'headline-1');
}

.headline-2 {
  @include mat.typography-level($my-custom-typography-config, 'headline-2');
}

.headline-3 {
  @include mat.typography-level($my-custom-typography-config, 'headline-3');
}

.headline-4 {
  @include mat.typography-level($my-custom-typography-config, 'headline-4');
}

.headline-5 {
  @include mat.typography-level($my-custom-typography-config, 'headline-5');
}

.headline-6 {
  @include mat.typography-level($my-custom-typography-config, 'headline-6');
}

.subtitle-1 {
  @include mat.typography-level($my-custom-typography-config, 'subtitle-1');
}

.subtitle-2 {
  @include mat.typography-level($my-custom-typography-config, 'subtitle-2');
}

.body-1 {
  @include mat.typography-level($my-custom-typography-config, 'body-1');
}

.body-2 {
  @include mat.typography-level($my-custom-typography-config, 'body-2');
}

.button {
  @include mat.typography-level($my-custom-typography-config, 'button');
}

.caption {
  @include mat.typography-level($my-custom-typography-config, 'caption');
}
