// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '../palettes.scss' as pal;
@use './typography.scss' as typo;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using http://mcg.mbitson.com/#!?mcgpalette0=%23876bea
$pal-primary: mat.define-palette(pal.$palette-primary);
$pal-accent: mat.define-palette(pal.$palette-accent);
$pal-error: mat.define-palette(pal.$palette-error);
$pal-warning: mat.define-palette(pal.$palette-warning);
$pal-success: mat.define-palette(pal.$palette-success);
$pal-grey: mat.define-palette(pal.$palette-grey);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cdz-real-estate-theme: mat.define-light-theme(
  (
    color: (
      primary: $pal-primary,
      accent: $pal-accent,
      warn: $pal-warning,
    ),
    typography: typo.$my-custom-typography-config,
    density: -3,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cdz-real-estate-theme);

/* You can add global styles to this file, and also import other style files */
