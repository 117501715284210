@import 'libs/ui/material-theme/configuration/ng-material.scss';
@import './override/mat-dialog.scss';
@import './override/mat-chips.scss';
@import './override/mat-slider.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

// RESOLVE BUG TAILWIND
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

